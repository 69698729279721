/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "what-is-svgr",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-svgr",
    "aria-label": "what is svgr permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is SVGR?"), "\n", React.createElement(_components.p, null, "SVGR is an universal tool to transform SVG into React components."), "\n", React.createElement(_components.p, null, "SVGR takes a raw SVG and transforms it into a ready-to-use React component."), "\n", React.createElement(_components.p, null, "For example, take the following SVG:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-xml"
  }, "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<svg\n  width=\"48px\"\n  height=\"1px\"\n  viewBox=\"0 0 48 1\"\n  version=\"1.1\"\n  xmlns=\"http://www.w3.org/2000/svg\"\n  xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n>\n  <!-- Generator: Sketch 46.2 (44496) - http://www.bohemiancoding.com/sketch -->\n  <title>Rectangle 5</title>\n  <desc>Created with Sketch.</desc>\n  <defs></defs>\n  <g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n    <g\n      id=\"19-Separator\"\n      transform=\"translate(-129.000000, -156.000000)\"\n      fill=\"#063855\"\n    >\n      <g id=\"Controls/Settings\" transform=\"translate(80.000000, 0.000000)\">\n        <g id=\"Content\" transform=\"translate(0.000000, 64.000000)\">\n          <g id=\"Group\" transform=\"translate(24.000000, 56.000000)\">\n            <g id=\"Group-2\">\n              <rect id=\"Rectangle-5\" x=\"25\" y=\"36\" width=\"48\" height=\"1\"></rect>\n            </g>\n          </g>\n        </g>\n      </g>\n    </g>\n  </g>\n</svg>\n")), "\n", React.createElement(_components.p, null, "After running SVGR, we got:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import * as React from 'react'\n\nconst SvgComponent = (props) => (\n  <svg width=\"1em\" height=\"1em\" viewBox=\"0 0 48 1\" {...props}>\n    <path d=\"M0 0h48v1H0z\" fill=\"currentColor\" fillRule=\"evenodd\" />\n  </svg>\n)\n\nexport default SvgComponent\n")), "\n", React.createElement(_components.p, null, "To achieve this result, SVGR applies several complex transformations:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Optimizing using ", React.createElement(_components.a, {
    href: "https://github.com/svg/svgo"
  }, "SVGO")), "\n", React.createElement(_components.li, null, "Transforming HTML into JSX in several steps:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Converting SVG into HAST (HTML AST)"), "\n", React.createElement(_components.li, null, "Converting HAST into Babel AST (JSX AST)"), "\n", React.createElement(_components.li, null, "Transforming AST using Babel (renaming attribute, changing attribute values, ...)"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Wrapping JSX into a React Component"), "\n", React.createElement(_components.li, null, "Converting Babel AST into code"), "\n", React.createElement(_components.li, null, "Formatting code using ", React.createElement(_components.a, {
    href: "https://prettier.io/"
  }, "Prettier")), "\n"), "\n", React.createElement(_components.p, null, "This complex pipeline makes SVGR a reliable, safe and extendable tool."), "\n", React.createElement(_components.p, null, "It can be used through:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/playground/"
  }, "Online playground")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/cli/"
  }, "Command line tool")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/webpack/"
  }, "webpack loader")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/node-api/"
  }, "Node.js API")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/rollup/"
  }, "rollup.js plugin")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/parcel/"
  }, "parcel plugin")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/next/"
  }, "Next.js plugin")), "\n"), "\n", React.createElement(_components.p, null, "It is just the list of official integrations, but the community around SVGR is huge. If you are looking for a plugin, it probably already exists. Give a look to ", React.createElement(_components.a, {
    href: "/docs/ecosystem/"
  }, "ecosystem"), " to learn more about existing SVGR integrations."), "\n", React.createElement(_components.p, null, "If you want to learn more, this conference talk is a great introduction:"), "\n", React.createElement("iframe", {
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/geKCzi7ZPkA",
    title: "YouTube video player",
    frameborder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
